import React, { useEffect, useState } from "react";
import { Link, Element, scroller } from "react-scroll";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import healthHubLogo from "../img/partners/health_hub_tirol.svg";
import startupTirolLogo from "../img/partners/logo-start-up-tirol-web.png";
import b2GreenHubLogo from "../img/partners/b2-green-hub.svg";
import standortTirolLogo from "../img/partners/tirol_cluster.svg";
import AWSLogo from "../img/partners/AWS_logo.svg";
import InncubatorLogo from "../img/partners/inncubator_logo.png";

function Main() {
  const [currentIdea, setCurrentIdea] = useState(0);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [isPaused, setIsPaused] = useState(false);

  const { t } = useTranslation();

  // let  public_key = process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY;
  useEffect(() => emailjs.init(process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY), []);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const target = hash.substring(1);
      scroller.scrollTo(target, {
        duration: 500,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const roadmapElement = document.querySelector(".roadmap");
      const roadmapHeading = document.querySelector(".roadmap-heading");
      const roadmapRect = roadmapElement.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (roadmapRect.top <= 0 && roadmapRect.bottom >= windowHeight) {
        roadmapHeading.style.position = "sticky";
        roadmapHeading.style.top = "100px";
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLeftClick = () => {
    setCurrentIdea((prevIdea) => (prevIdea - 1 + 4) % 4);
  };

  const handleRightClick = () => {
    setCurrentIdea((prevIdea) => (prevIdea + 1) % 4);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleSend = async (e) => {
    if (!validateEmail(email)) {
      console.log("Invalid email address.");
      return;
    }

    e.preventDefault();
    const serviceId = process.env.REACT_APP_EMAIL_JS_SERVICE_ID;
    const templateId = process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID;
    try {
      await emailjs.send(serviceId, templateId, {
        name: fullName,
        email: email,
        phone: phone,
        message: message,
      });
      alert("Email successfully sent");
    } catch (error) {
      console.log(error);
    } finally {
      setFullName("");
      setEmail("");
      setPhone("");
      setCompany("");
      setMessage("");
    }
  };

  const partners = [
    {
      id: 2,
      name: "Health Hub Tirol",
      logoUrl: healthHubLogo,
      link: "https://www.healthhub.tirol/",
    },
    {
      id: 3,
      name: "Startup Tirol",
      logoUrl: startupTirolLogo,
      link: "https://www.startup.tirol/",
    },
    {
      id: 6,
      name: "AWS",
      logoUrl: AWSLogo,
      link: "https://aws.amazon.com/",
      className: "height-150 mt-125",
    },
    {
      id: 7,
      name: "INNCUBATOR",
      logoUrl: InncubatorLogo,
      link: "https://inncubator.at/",
      className: "height-100 mt-150",
    },
    {
      id: 5,
      name: "Standort Tirol",
      logoUrl: standortTirolLogo,
      link: "https://www.standort-tirol.at/",
      className: "height-150 mt-125",
    },
    {
      id: 4,
      name: "B2 Green Hub",
      logoUrl: b2GreenHubLogo,
      link: "https://b2greenhub.eu/",
    },
  ];

  return (
    <div className="App">
      <Element name="landing" className=" grid fr-2 section landing">
        <div className="landing-header">
          <h1>
            Pred<span>SENSE</span>
          </h1>
          <h4>{t("SloganShort")}</h4>
          <button>
            <Link to="contact" smooth={true} duration={500}>
              {t("ContactUsH")}
            </Link>
          </button>
        </div>
        <div className="landing-image-container">
          <div className="landing-image">
            <div className="landing-image-monitor"></div>
            <div className="landing-image-pie"></div>
            <div className="landing-image-coaching"></div>
          </div>
        </div>
      </Element>

      <Element name="vision" className="section vision">
        <div className="content-wrapper">
          <div className="vision-content">
            <h1>{t("VisionH")}</h1>
            <h4>{t("VisionShort")}</h4>
          </div>
          <div className="mission-content">
            <h1>{t("MissionH")}</h1>
            <h4>{t("MissionShort")}</h4>
          </div>
        </div>
      </Element>

      <Element name="idea" className="section idea">
        <h1>{t("AIToolForPMH")}</h1>
        <h4>{t("AIToolForPMShort")}</h4>
        <div className="idea-container">
          <div className="idea-left" onClick={handleLeftClick}>
            &lt;
          </div>

          <div
            className={`grid fr-2-1 idea-item ${
              currentIdea === 0 ? "active" : ""
            }`}
          >
            <div className="idea-image product1"></div>
            <div className="idea-info">
              <h3>{t("IdeaTitle1")}</h3>
              <p>{t("IdeaContent1")}</p>
            </div>
          </div>

          <div
            className={`grid fr-1-2 idea-item ${
              currentIdea === 1 ? "active" : ""
            }`}
          >
            <div className="idea-info">
              <h3>{t("IdeaTitle2")}</h3>
              <p>{t("IdeaContent2")}</p>
            </div>
            <div className="idea-image product3"></div>
          </div>

          <div
            className={`grid fr-2-1 idea-item ${
              currentIdea === 2 ? "active" : ""
            }`}
          >
            <div className="idea-image product2"></div>
            <div className="idea-info">
              <h3>{t("IdeaTitle3")}</h3>
              <p>{t("IdeaContent3")}</p>
            </div>
          </div>

          <div
            className={`grid fr-1-2 idea-item ${
              currentIdea === 3 ? "active" : ""
            }`}
          >
            <div className="idea-info">
              <h3>{t("IdeaTitle4")}</h3>
              <p>{t("IdeaContent4")}</p>
            </div>
            <div className="idea-image product4"></div>
          </div>

          <div className="idea-right" onClick={handleRightClick}>
            {" "}
            &gt;
          </div>
        </div>
      </Element>

      <Element name="roadmap" className="section roadmap">
        <h1>{t("RoadmapH")}</h1>
        <div className="roadmap-container grid fr-2">
          <div className="roadmap-heading">
            <h1>{t("RoadmapSloganH")}</h1>
            <h4>{t("RoadmapSloganContent")}</h4>
          </div>
          <div className="roadmap-steps-container">
            <div className="roadmap-steps">
              <div className="roadmap-step">
                <div className={`roadmap-step-num completed-step-num`}>
                  <h3>1.</h3>
                </div>
                <div className="roadmap-step-info">
                  <h3>{t("ProblemStatement")}</h3>
                  <p>{t("RoadmapProblemStatementContent")}</p>
                </div>
              </div>
              <div className="roadmap-step">
                <div className={`roadmap-step-num completed-step-num`}>
                  <h3>2.</h3>
                </div>
                <div className="roadmap-step-info">
                  <h3>{t("Solution")}</h3>
                  <p>{t("RoadmapSolutionContent")}</p>
                </div>
              </div>
              <div className="roadmap-step">
                <div className={`roadmap-step-num completed-step-num`}>
                  <h3>3.</h3>
                </div>
                <div className="roadmap-step-info">
                  <h3>{t("POC")}</h3>
                  <p>{t("RoadmapPOCContent")}</p>
                </div>
              </div>
              <div className="roadmap-step">
                <div className={`roadmap-step-num completed-step-num`}>
                  <h3>4.</h3>
                </div>
                <div className="roadmap-step-info">
                  <h3>{t("BusinessPlan")}</h3>
                  <p>{t("RoadmapBusinessPlanContent")}</p>
                </div>
              </div>
              <div className="roadmap-step">
                <div className="roadmap-step-num progress-step-num">
                  <h3>5.</h3>
                </div>
                <div className="roadmap-step-info">
                  <h3>{t("SeedInvestment")}</h3>
                  <p>{t("RoadmapSeedInvestmentContent")}</p>
                </div>
              </div>
              <div className="roadmap-step">
                <div className="roadmap-step-num progress-step-num">
                  <h3>6.</h3>
                </div>
                <div
                  className="roadmap-step-info"
                  style={{ marginBottom: "16px" }}
                >
                  <h3>{t("EarlyAdopter")}</h3>
                  <p>{t("RoadmapEarlyAdopterContent")}</p>
                </div>
              </div>
              {/*<div className="roadmap-step">
                <div className="roadmap-step-num">
                  <h3>6.</h3>
                </div>
                <div className="roadmap-step-info">
                  <h3>Minimum Working Product</h3>
                  <p>
                    We develop a minimum working product that delivers core
                    functionalities. This product allows us to gather feedback
                    and make improvements.
                  </p>
                </div>
              </div>
              <div className="roadmap-step">
                <div className="roadmap-step-num">
                  <h3>7.</h3>
                </div>
                <div className="roadmap-step-info">
                  <h3>First Customer</h3>
                  <p>
                    We acquire our first customer, marking a significant
                    milestone. This validates our solution and sets the stage
                    for future growth and expansion.
                  </p>
                </div>
              </div>*/}
            </div>
          </div>
        </div>
      </Element>

      <Element name="team" className="section team">
        <h5>{t("IntroducingMembers")}</h5>
        <h1>{t("MeetOurTeamH")}</h1>

        <div className="team-container grid fr-3">
          <div className="team-item">
            <div className="team-item-inner">
              <div className="member-image" id="dali-image"></div>
              <div className="member-info-overlay">
                <p className="member-details">{t("DaliInfo")}</p>
              </div>
              <div className="member-info">
                <h3>Dalibor Mitic</h3>
                <h3>CEO</h3>
                <p>{t("DaliRole")}</p>
              </div>
            </div>
          </div>

          <div className="team-item">
            <div className="team-item-inner">
              <div className="member-image" id="ilija-image" />
              <div className="member-info-overlay">
                <p className="member-details">{t("IlijaInfo")}</p>
              </div>
              <div className="member-info">
                <h3>Ilija Mikic</h3>
                <h3>CTO</h3>
                <p>{t("IlijaRole")}</p>
              </div>
            </div>
          </div>

          <div className="team-item">
            <div className="team-item-inner">
              <div className="member-image" id="milan-image" />
              <div className="member-info-overlay">
                <p className="member-details">{t("MilanInfo")}</p>
              </div>
              <div className="member-info">
                <h3>Milan Mitic</h3>
                <h3>COO</h3>
                <p>{t("MilanRole")}</p>
              </div>
            </div>
          </div>
        </div>
        {/*
        <div className="join-us">
          <h4>
            {t("InterestedInJoining")}
            <br />
            {t("SenUsCV")}
          </h4>
          <button>{t("SendCVH")}</button>
        </div>*/}
      </Element>

      <Element name="partners" className="section">
        <h1>{t("MeetOurPartnersH")}</h1>
        <div
          className={`partners-container ${isPaused ? "paused" : ""}`}
          onMouseEnter={() => setIsPaused(true)}
          onMouseLeave={() => setIsPaused(false)}
        >
          <div className="partners-track">
            {[...partners, ...partners].map((partner, index) => (
              <a
                key={index}
                href={partner.link}
                target="_blank"
                rel="noopener noreferrer"
                className="partner-item"
              >
                <img
                  src={partner.logoUrl}
                  alt={`${partner.name} logo`}
                  className={`${partner.className} partner-logo`}
                />
              </a>
            ))}
          </div>
        </div>
      </Element>

      <Element name="contact" className="section contact">
        <h1>{t("SendUaMessageH")}</h1>
        <div className="contact-form-container">
          <form className="contact-form" onSubmit={handleSend}>
            <input
              required
              type="text"
              placeholder={t("FullName")}
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
            <input
              type="text"
              placeholder={`${t("CompanyName")} (${t("Optional")})`}
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
            <input
              required
              type="email"
              placeholder={t("Email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              required
              type="text"
              placeholder={t("Phone")}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <textarea
              required
              rows="4"
              placeholder={t("Message")}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <button>{t("SendH")}</button>
          </form>
        </div>
      </Element>
    </div>
  );
}

export default Main;
